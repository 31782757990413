var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Básico")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Viajes")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Formulario "+_vm._s(_vm.accion)+" ")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header pt-2 pb-2 border-0"},[_c('div',{staticClass:"col-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Formulario "+_vm._s(_vm.accion)+" Viajes")])])]),_c('div',{staticClass:"card-body p-0"},[_vm._m(1),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"Principal"}},[_c('div',{staticClass:"card-body pb-0"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"vigencia_guia"}},[_vm._v("Tipo Operación Viaje")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tipo_operacion),expression:"form.tipo_operacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.tipo_operacion.$invalid
                              ? 'is-invalid'
                              : 'is-valid',on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "tipo_operacion", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.validarPostulacionComercial]}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipoOperacion),function(operacion){return _c('option',{key:operacion.numeracion,domProps:{"value":operacion.numeracion}},[_vm._v(" "+_vm._s(operacion.descripcion)+" ")])})],2)]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"tipo_vehiculo"}},[_vm._v("Tipo Vehículo")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tipo_vehiculo_id),expression:"form.tipo_vehiculo_id"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.tipo_vehiculo_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"disabled":_vm.form.tipo_operacion == 2},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "tipo_vehiculo_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipoVehiculo),function(tipoVehi){return _c('option',{key:tipoVehi.id,domProps:{"value":tipoVehi.id}},[_vm._v(" "+_vm._s(tipoVehi.nombre)+" ")])})],2)]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"vehiculo"}},[_vm._v("Vehículo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.vehiculo),expression:"form.vehiculo"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.vehiculo.$invalid
                              ? 'is-invalid'
                              : 'is-valid',staticStyle:{"text-transform":"uppercase"},attrs:{"type":"text","onkeyup":"javascript:this.value=this.value.toUpperCase();"},domProps:{"value":(_vm.form.vehiculo)},on:{"blur":_vm.validarPostulacionComercial,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "vehiculo", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"fecha_cargue"}},[_vm._v("Fecha Cargue")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_cargue),expression:"form.fecha_cargue"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha_cargue.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"date","id":"fecha_cargue"},domProps:{"value":(_vm.form.fecha_cargue)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_cargue", $event.target.value)},_vm.validarPostulacionComercial]}})]),_c('div',{staticClass:"form-group col-md-1"},[_c('label',{attrs:{"for":"hora_cargue"}},[_vm._v("Hora Cargue")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hora_cargue),expression:"form.hora_cargue"},{name:"mask",rawName:"v-mask",value:({
                            mask: '99:99',
                            hourFormat: '24',
                          }),expression:"{\n                            mask: '99:99',\n                            hourFormat: '24',\n                          }"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.hora_cargue.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.form.hora_cargue)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "hora_cargue", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"clase_viaje"}},[_vm._v("Clase Viaje")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.clase_viaje),expression:"form.clase_viaje"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.clase_viaje.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"disabled":_vm.form.tipo_operacion == 2},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "clase_viaje", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.claseViaje),function(estado){return _c('option',{key:estado.numeracion,domProps:{"value":estado.numeracion}},[_vm._v(" "+_vm._s(estado.descripcion)+" ")])})],2)]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"remolque"}},[_vm._v("Remolque")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.remolque),expression:"form.remolque"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.remolque.$invalid
                              ? 'is-invalid'
                              : 'is-valid',staticStyle:{"text-transform":"uppercase"},attrs:{"type":"text","onkeyup":"javascript:this.value=this.value.toUpperCase();"},domProps:{"value":(_vm.form.remolque)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "remolque", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"conductor"}},[_vm._v("Nombre Conductor")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.conductor),expression:"form.conductor"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.conductor.$invalid
                              ? 'is-invalid'
                              : 'is-valid',staticStyle:{"text-transform":"uppercase"},attrs:{"type":"text","onkeyup":"javascript:this.value=this.value.toUpperCase();"},domProps:{"value":(_vm.form.conductor)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "conductor", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"n_conductor"}},[_vm._v("N° Documento")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.documento_conductor),expression:"form.documento_conductor"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.documento_conductor.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"number"},domProps:{"value":(_vm.form.documento_conductor)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "documento_conductor", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"sitio"}},[_vm._v("Último Sitio")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sitio),expression:"form.sitio"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.form.sitio)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "sitio", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"sitio_origen"}},[_vm._v("Sitio Origen")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sitioOrigen),expression:"form.sitioOrigen"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.form.sitioOrigen)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "sitioOrigen", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"orden_cargue"}},[_vm._v("Orden de cargue")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.orden_de_cargue),expression:"form.orden_de_cargue"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.orden_de_cargue.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"number","id":"orden_cargue"},domProps:{"value":(_vm.form.orden_de_cargue)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "orden_de_cargue", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"fecha_final"}},[_vm._v("Fecha final")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_final),expression:"form.fecha_final"}],staticClass:"form-control form-control-sm",attrs:{"readonly":_vm.acto != 1,"type":"date","id":"fecha_final"},domProps:{"value":(_vm.form.fecha_final)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_final", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"hora_final"}},[_vm._v("Hora final")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hora_final),expression:"form.hora_final"},{name:"mask",rawName:"v-mask",value:({
                            mask: '99:99',
                            hourFormat: '24',
                          }),expression:"{\n                            mask: '99:99',\n                            hourFormat: '24',\n                          }"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.hora_final.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"text","readonly":_vm.acto != 1,"id":"hora_final"},domProps:{"value":(_vm.form.hora_final)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "hora_final", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"transportadora"}},[_vm._v("Transportadora")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                            _vm.$v.form.transportadora_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                          ],attrs:{"placeholder":"Transportadoras","label":"razon_social","options":_vm.listasForms.transportadoras},on:{"search":_vm.buscarEmpresas,"input":function($event){return _vm.selectempresa()}},model:{value:(_vm.transportadora),callback:function ($$v) {_vm.transportadora=$$v},expression:"transportadora"}})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"tipo_mercancia"}},[_vm._v("Tipo Mercancía")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tipo_producto_id),expression:"form.tipo_producto_id"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.tipo_producto_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "tipo_producto_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipoProducto),function(prod){return _c('option',{key:prod.id,domProps:{"value":prod.id}},[_vm._v(" "+_vm._s(prod.nombre)+" ")])})],2)])])])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('button',{staticClass:"btn bg-secondary col-md-1",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fas fa-reply"}),_c('br'),_vm._v("Volver ")]),(
                        _vm.$store.getters.can(
                          'hidrocarburos.ventanasOperaciones.crearViajeRegalias'
                        )
                      )?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.form.$invalid),expression:"!$v.form.$invalid"}],staticClass:"btn bg-primary col-md-1",on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_c('br'),_vm._v("Guardar ")]):_vm._e()])])])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Viajes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"tab-Principal","data-toggle":"tab","href":"#Principal"}},[_vm._v("Principal")])])])
}]

export { render, staticRenderFns }