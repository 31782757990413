<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Viajes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">Viajes</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2 border-0">
                <div class="col-6">
                  <h5 class="mb-0">Formulario {{ accion }} Viajes</h5>
                </div>
              </div>
              <div class="card-body p-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-Principal"
                      data-toggle="tab"
                      href="#Principal"
                      >Principal</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="Principal">
                    <div class="card-body pb-0">
                      <div class="row justify-content-center">
                        <div class="form-group col-md-3">
                          <label for="vigencia_guia"
                            >Tipo Operación Viaje</label
                          >
                          <select
                            class="form-control form-control-sm"
                            v-model="form.tipo_operacion"
                            :class="
                              $v.form.tipo_operacion.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @change="validarPostulacionComercial"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="operacion in listasForms.tipoOperacion"
                              :key="operacion.numeracion"
                              :value="operacion.numeracion"
                            >
                              {{ operacion.descripcion }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-2">
                          <label for="tipo_vehiculo">Tipo Vehículo</label>
                          <select
                            v-model="form.tipo_vehiculo_id"
                            class="form-control form-control-sm"
                            :class="
                              $v.form.tipo_vehiculo_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="form.tipo_operacion == 2"
                          >
                            <option>Seleccione...</option>
                            <option
                              v-for="tipoVehi in listasForms.tipoVehiculo"
                              :key="tipoVehi.id"
                              :value="tipoVehi.id"
                            >
                              {{ tipoVehi.nombre }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-2">
                          <label for="vehiculo">Vehículo</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            style="text-transform: uppercase"
                            onkeyup="javascript:this.value=this.value.toUpperCase();"
                            v-model="form.vehiculo"
                            :class="
                              $v.form.vehiculo.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @blur="validarPostulacionComercial"
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label for="fecha_cargue">Fecha Cargue</label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            id="fecha_cargue"
                            v-model="form.fecha_cargue"
                            :class="
                              $v.form.fecha_cargue.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            @input="validarPostulacionComercial"
                          />
                        </div>
                        <div class="form-group col-md-1">
                          <label for="hora_cargue">Hora Cargue</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.hora_cargue"
                            :class="
                              $v.form.hora_cargue.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            v-mask="{
                              mask: '99:99',
                              hourFormat: '24',
                            }"
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label for="clase_viaje">Clase Viaje</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.clase_viaje"
                            :class="
                              $v.form.clase_viaje.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="form.tipo_operacion == 2"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="estado in listasForms.claseViaje"
                              :key="estado.numeracion"
                              :value="estado.numeracion"
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-2">
                          <label for="remolque">Remolque</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            style="text-transform: uppercase"
                            onkeyup="javascript:this.value=this.value.toUpperCase();"
                            v-model="form.remolque"
                            :class="
                              $v.form.remolque.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="conductor">Nombre Conductor</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            style="text-transform: uppercase"
                            onkeyup="javascript:this.value=this.value.toUpperCase();"
                            v-model="form.conductor"
                            :class="
                              $v.form.conductor.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label for="n_conductor">N° Documento</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="form.documento_conductor"
                            :class="
                              $v.form.documento_conductor.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label for="sitio">Último Sitio</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.sitio"
                            readonly
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label for="sitio_origen">Sitio Origen</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.sitioOrigen"
                            readonly
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label for="orden_cargue">Orden de cargue</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="orden_cargue"
                            v-model="form.orden_de_cargue"
                            :class="
                              $v.form.orden_de_cargue.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label for="fecha_final">Fecha final</label>
                          <input
                            :readonly="acto != 1"
                            type="date"
                            class="form-control form-control-sm"
                            id="fecha_final"
                            v-model="form.fecha_final"
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label for="hora_final">Hora final</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            :readonly="acto != 1"
                            v-model="form.hora_final"
                            id="hora_final"
                            :class="
                              $v.form.hora_final.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            v-mask="{
                              mask: '99:99',
                              hourFormat: '24',
                            }"
                          />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="transportadora">Transportadora</label>
                          <v-select
                            :class="[
                              $v.form.transportadora_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="transportadora"
                            placeholder="Transportadoras"
                            label="razon_social"
                            class="form-control form-control-sm p-0"
                            @search="buscarEmpresas"
                            @input="selectempresa()"
                            :options="listasForms.transportadoras"
                          >
                          </v-select>
                        </div>
                        <div class="form-group col-md-2">
                          <label for="tipo_mercancia">Tipo Mercancía</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="form.tipo_producto_id"
                            :class="
                              $v.form.tipo_producto_id.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="prod in listasForms.tipoProducto"
                              :key="prod.id"
                              :value="prod.id"
                            >
                              {{ prod.nombre }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <button class="btn bg-secondary col-md-1" @click="back()">
                        <i class="fas fa-reply"></i><br />Volver
                      </button>
                      <button
                        class="btn bg-primary col-md-1"
                        v-show="!$v.form.$invalid"
                        @click="save()"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.ventanasOperaciones.crearViajeRegalias'
                          )
                        "
                      >
                        <i class="fas fa-paper-plane"></i><br />Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required, helpers } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import moment from "moment";

const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "ViajeFormRegalias",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      falgValidando: false,
      cargando: false,
      form: {
        guia_id: null,
        tipo_vehiculo_id: null,
        vehiculo: null,
        conductor: null,
        documento_conductor: null,
        remolque: null,
        fecha_cargue: null,
        hora_cargue: null,
        fecha_final: null,
        hora_final: null,
        orden_de_cargue: null,
        clase_viaje: null,
        vigencia_guia: null,
        sitio_type: "App\\Sitio",
        sitio_id: null,
        sitio_origen_id: null,
        transportadora_id: null,
        estado: 1,
        accion: 1,
      },
      transportadora: [],
      id: null,
      //arreglos
      accion: "",
      acto: null,
      metodo: "",
      producto_liquido: [],
      sitio: [],
      sitio_type: null,
      sitio_id: null,
      tipo_control: null,
      //listas
      listasForms: {
        productos_liquidos: [],
        sitios: [],
        claseViaje: [],
        tipoOperacion: [],
        tipoVehiculo: [],
        tipoProducto: [],
        transportadoras: [],
      },
    };
  },
  validations: {
    form: {
      vehiculo: {
        required,
      },
      conductor: {
        required,
      },
      documento_conductor: {
        required,
      },
      remolque: {
        required,
      },
      transportadora_id: {
        required,
      },
      clase_viaje: {
        required,
      },
      fecha_cargue: {
        required,
      },
      hora_cargue: {
        required,
        timeFormat,
      },
      sitio_origen_id: {
        required,
      },
      orden_de_cargue: {
        required,
      },
      sitio_id: {
        required,
      },
      tipo_operacion: {
        required,
      },
      tipo_vehiculo_id: {
        required,
      },
      tipo_producto_id: {
        required,
      },
      hora_final: {
        timeFormat,
      },
    },
  },
  methods: {
    init() {
      this.cargando = true;
      this.sitio_type = this.$route.params.sitio_type;
      this.sitio_id = this.$route.params.sitio_id;
      this.tipo_control = this.$route.params.tipo_control;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.metodo = "PUT";

        if (this.$route.params.sitio_type == 1) {
          this.form.sitio_type = "App\\Sitio";
        } else if (this.$route.params.sitio_type == 2) {
          this.form.sitio_type = "App\\PuntoGestion";
        }

        this.form.sitio = this.form.sitio.nombre;
        this.form.sitioOrigen = this.form.sitio.nombre;

        this.acto = 1;
      } else {
        this.metodo = "POST";
        this.id = this.$route.params.id;
        this.acto = this.$route.params.acto;
        this.form.sitio_id = this.$route.params.sitio_id;
        this.form.sitio_origen_id = this.$route.params.sitio_id;
        this.form.sitio_type = this.$route.params.sitio_type;
        this.form.sitio = this.$route.params.sitio;
        this.form.sitioOrigen = this.$route.params.sitio;

        if (this.$route.params.sitio_type == 1) {
          this.form.sitio_type = "App\\Sitio";
        } else if (this.$route.params.sitio_type == 2) {
          this.form.sitio_type = "App\\PuntoGestion";
        }
      }
    },

    getTipoOperacion() {
      axios.get("/api/lista/57").then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].numeracion != 1)
            this.listasForms.tipoOperacion.push(response.data[i]);
        }
      });
    },

    getEstadosVigencia() {
      axios.get("/api/lista/45").then((response) => {
        this.listasForms.estadosVigencia = response.data;
      });
    },

    getEstadosClase() {
      axios.get("/api/lista/43").then((response) => {
        this.listasForms.claseViaje = response.data;
      });
    },

    getTiposVehiculos() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then(function (response) {
          let respuesta = response.data;
          me.listasForms.tipoVehiculo = respuesta;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTiposProducto() {
      axios.get("/api/admin/productos/lista").then((response) => {
        this.cargando = false;
        this.listasForms.tipoProducto = response.data;
      });
    },

    buscarSitio(search) {
      let me = this;
      this.listasForms.sitios = [];
      if (search != "") {
        var url = "api/admin/sitios/lista?nombre=" + search;
        axios
          .get(url)
          .then(function (response) {
            me.listasForms.sitios = response.data;
          })
          .catch(function (e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectempresa() {
      this.form.transportadora_id = "";
      if (this.transportadora) {
        this.form.transportadora_id = this.transportadora.id;
      }
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url)
          .then(function (response) {
            let respuesta = response.data;
            me.listasForms.transportadoras = respuesta;
            loading(false);
          })
          .catch(function (error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    validarPostulacionComercial() {
      this.form.com_det_postulacion_id = null;
      this.form.tipo_vehiculo_id =
        this.form.tipo_operacion == 2 ? 1 : this.form.tipo_vehiculo_id;
      this.form.clase_viaje =
        this.form.tipo_operacion == 2 ? 1 : this.form.clase_viaje;

      if (
        this.form.tipo_operacion == 2 &&
        this.form.vehiculo &&
        this.form.fecha_cargue
      ) {
        this.cargando = true;
        this.form.cliente_id = null;
        this.form.despachado_a = null;
        this.form.com_det_postulacion_id = null;
        this.form.remolque = null;
        this.form.conductor = null;
        this.form.documento_conductor = null;
        this.form.hora_cargue = null;

        axios
          .get(
            "/api/hidrocarburos/ventanasEnturnamientoOperacion/validarPostulacionComercial",
            {
              params: this.form,
            }
          )
          .then((response) => {
            this.cargando = false;
            console.log(`Object.keys(response.data).length`);
            console.log(Object.keys(response.data).length);
            if (Object.keys(response.data).length > 0) {
              console.log(`response.data`);
              console.log(response.data);

              this.form.cliente_id = response.data.det_oferta.oferta.cliente.id;
              this.form.despachado_a =
                response.data.det_oferta.oferta.cliente.razon_social;
              this.form.com_det_postulacion_id = response.data.id;
              this.form.remolque = response.data.remolque;
              this.form.conductor = response.data.conductor_sub
                ? `${response.data.conductor_sub.nombres} ${response.data.conductor_sub.apellidos}`
                : null;
              this.form.documento_conductor = response.data.conductor_sub
                ? response.data.conductor_sub.numero_documento
                : null;
              this.form.hora_cargue = moment(response.data.fecha_cargue).format("HH:mm");

              this.$swal({
                icon: "info",
                title: "Atención!",
                html: `Se encontró una postulacion del módulo Comercial del cliente <strong>${response.data.det_oferta.oferta.cliente.razon_social}</strong>`,
                timer: 4000,
                timerProgressBar: true,
              });
            }
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/hidrocarburos/detalleGuiasRegalias",
          data: this.form,
        })
          .then(() => {
            this.cargando = false;
            this.$swal({
              icon: "success",
              title: "El viaje se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });

            const sitioID = this.sitio_id;
            const sitioType = this.sitio_type;
            const tipoControl = this.tipo_control;
            this.$router.replace({
              path: `/Hidrocarburos/VentanasOperaciones/${sitioID}/${sitioType}/${tipoControl}`,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace({
        path: `/Hidrocarburos/VentanasOperaciones/${this.sitio_id}/${this.sitio_type}/${this.tipo_control}`,
      });
    },
  },

  beforeMount() {
    this.getEstadosVigencia();
    this.getEstadosClase();
    this.getTipoOperacion();
    this.getTiposVehiculos();
    this.getTiposProducto();
  },

  mounted() {
    this.init();
  },
};
</script>
